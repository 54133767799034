import(/* webpackMode: "eager", webpackExports: ["CategoryPageEffects"] */ "/builds/empiriecom/core/frontend/turborepo/apps/shopping/src/app/[lang]/(shop)/(search)/c/[...categories]/CategoryPageEffects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SharedPageEffects"] */ "/builds/empiriecom/core/frontend/turborepo/apps/shopping/src/app/[lang]/(shop)/(search)/SharedPageEffects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoaderSwrConfig"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/components/LoaderSwrConfig/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionBanner"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/modules/PromotionBanner/PromotionBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionBannerEmpty"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/modules/PromotionBanner/PromotionBannerEmpty.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryPageBrandOverview"] */ "/builds/empiriecom/core/frontend/turborepo/packages/search/src/components/CategoryPageBrandOverview/CategoryPageBrandOverview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryPageCmsContentOnly"] */ "/builds/empiriecom/core/frontend/turborepo/packages/search/src/components/CategoryPageCmsContentOnly/CategoryPageCmsContentOnly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryPageDefaultContent"] */ "/builds/empiriecom/core/frontend/turborepo/packages/search/src/components/CategoryPageDefaultContent/CategoryPageDefaultContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchDebugPanel"] */ "/builds/empiriecom/core/frontend/turborepo/packages/search/src/components/SearchDebugPanel/SearchDebugPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tracking"] */ "/builds/empiriecom/core/frontend/turborepo/packages/search/src/components/Tracking/Tracking.tsx");
;
import(/* webpackMode: "eager" */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OnlyInDebugMode"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/OnlyInDebugMode/OnlyInDebugMode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["nookieParseCookies","nookieSetCookie","nookieDestroyCookie"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/CookieProvider/CookieProvider.tsx");
