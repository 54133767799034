'use client';

import { useEffect, type FC } from 'react';
import { useSetAtom } from 'jotai';
import { usePathname } from 'next/navigation';

import { languageAlternatesAtom } from '@packages/modules/src/Header/Toolbar/Slots/LocaleSwitchSlot.atom';

export const CategoryPageEffects: FC<{
  alternateLinks?: Record<string, string>;
}> = ({ alternateLinks }) => {
  const pathname = usePathname();
  const setLanguageAlternates = useSetAtom(languageAlternatesAtom);

  useEffect(() => {
    setLanguageAlternates({
      currentPathname: pathname ?? 'unknown',
      alternateLinks,
    });
  }, [alternateLinks, pathname, setLanguageAlternates]);

  return null;
};
